<template>
  <div class="w-100">
    <div class="d-flex flex-column">
      <v-card class="w-100 rounded-t-0">
        <v-card-text class="pb-0">
          <div class="d-flex align-center">
            <div class="mr-2 mb-1 dark-font-color app-medium-font">
              To
            </div>
          </div>
          <div
            class="d-flex flex-wrap align-center px-3 py-1 to-email mb-3"
            style="border-radius: 5px;"
          >
            <v-chip
              label
              small
              class="mr-3 mb-2"
              v-for="chip in toArray"
              :key="chip"
            >
              <span class="pr-2">
                {{ chip }}
              </span>
              <v-icon small @click="onAddFans(chip)" v-if="isAgency(chip)">
                mdi-account-check
              </v-icon>
              <v-icon small @click="onCloseChip(chip)">
                $delete
              </v-icon>
            </v-chip>
            <div>
              <v-menu offset-y v-model="toEmailPopup">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="searchKey"
                    ref="toEmail"
                    hide-details
                    placeholder="Type to search"
                    solo
                    flat
                    dense
                    class="mb-2 new-email-input"
                    style="min-width: 100dp; height: 25px"
                    elevation="0"
                    v-bind="attrs"
                    v-on="on"
                    autocomplete="off"
                    @keyup.enter="onSelectFirstUser"
                  >
                  </v-text-field>
                </template>
                <div style="max-height: 60vh">
                  <v-list>
                    <v-list-item
                      v-for="(item, index) in filteredUsers"
                      :key="index"
                      class="to-email-list-item py-1"
                    >
                      <v-list-item-title
                        v-if="item.header"
                        style="font-weight: bold;"
                        >{{ item.header }}</v-list-item-title
                      >
                      <v-list-item-title
                        v-else
                        @click="selectUser(item)"
                        class="d-flex"
                      >
                        <editable-avatar
                          :editable="false"
                          :image="item.thumbnail ? item.thumbnail : item.image"
                          :size="40"
                          :shadow="false"
                          class="mr-3"
                        />
                        <div class="d-flex flex-column">
                          <div>{{ item.name }}</div>
                          <div class="font-14 app-bold-font mt-1">
                            {{ item.email }}
                          </div>
                        </div>
                      </v-list-item-title>
                    </v-list-item>
                    <v-divider v-if="filteredUsers.length" />
                    <v-list-item @click="onSelectAll">
                      <v-list-item-title>
                        <v-icon class="mr-2">mdi-account-group</v-icon>Select
                        All
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="onSelectAllAgencies">
                      <v-list-item-title>
                        <v-icon class="mr-2">mdi-domain</v-icon>Select All
                        Agencies
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="onSelectAllVolunteers">
                      <v-list-item-title>
                        <v-icon class="mr-2">mdi-account-multiple</v-icon>Select
                        All Volunteers
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </div>
              </v-menu>
            </div>
          </div>
        </v-card-text>
        <v-card-text class="py-0">
          <div class="d-flex align-center">
            <div class="mr-2 mb-1 dark-font-color app-medium-font">
              From
            </div>
          </div>
          <v-text-field
            v-model="fromEmail"
            outlined
            dense
            readonly
            placeholder="Email"
            @input="onChange"
            :rules="[rules.required, rules.email]"
          />
        </v-card-text>
        <v-card-text class="py-0">
          <div class="d-flex align-center">
            <div class="mr-2 mb-1 dark-font-color app-medium-font">
              Subject
            </div>
          </div>
          <v-text-field
            v-model="subject"
            outlined
            dense
            placeholder="Subject"
            @input="onChange"
          />
        </v-card-text>
        <v-card-text class="pt-0">
          <div class="editor">
            <vue-editor
              :editorOptions="editorSettings"
              v-model="emailContent"
              useCustomImageHandler
              @image-added="imageHandler"
            />
          </div>
        </v-card-text>
        <v-card-actions class="mb-3 pl-5 d-flex justify-end">
          <v-btn @click="onDelete" v-if="$route.query._id" class="ml-3"
            >Delete</v-btn
          >
          <v-btn @click="onSend" :loading="loading" color="primary mr-3">
            {{ $route.query._id ? "Resend" : "Send" }}
          </v-btn>
        </v-card-actions>
        <v-card-text v-if="errorMessage">
          <v-alert
            dense
            type="error"
            v-text="errorMessage"
            v-if="errorMessage"
          />
        </v-card-text>
      </v-card>
      <v-snackbar v-model="snackbar">
        {{ snackMessage }}
      </v-snackbar>
      <confirm-delete-dialog
        :deleteDialog="deleteDialog"
        :onClose="onCloseDialog"
        :onConfirm="onConfirmDelete"
      />
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Quill from "quill";
import EditableAvatar from "@/components/EditableAvatar.vue";
import ConfirmDeleteDialog from "@/components/common/ConfirmDeleteDialog.vue";
window.Quill = Quill;
const ImageResize = require("quill-image-resize-module").default;
Quill.register("modules/imageResize", ImageResize);
const VideoResize = require("quill-video-resize-module").default;
Quill.register("modules/VideoResize", VideoResize);

export default {
  components: { EditableAvatar, ConfirmDeleteDialog },
  data() {
    return {
      fromEmail: "info@akindercompany.com",
      subject: "",
      emailContent: "",
      rules: {
        required: (value) => !!value || "Required.",
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
      editorSettings: {
        modules: {
          imageResize: {},
          VideoResize: {},
        },
      },
      toEmailPopup: false,
      searchKey: null,
      filteredUsers: [],
      toArray: [],
      emailTypeData: {},
      editing: null,
      loading: false,
      deleteDialog: false,
      isDeleting: false,
      snackbar: false,
      snackMessage: null,
      errorMessage: null,
      errorDelete: null,
      errorDeleteMessage: null,
    };
  },
  methods: {
    ...mapActions({
      uploadBlogImage: "blog/uploadBlogImage",
      sendEmail: "auth/sendAdminEmail",
      getTypeFromEmail: "auth/getTypeFromEmail",
      searchUsers: "chat/searchUsersEmail",
      getMessageLog: "messagelog/getLogDetails",
      deleteMessageLog: "messagelog/deleteLog",
    }),
    onChange() {
      this.errorMessage = null;
      this.errorDelete = null;
      this.errorDeleteMessage = null;
    },
    onSend() {
      this.errorMessage = null;
      if (
        this.fromEmail &&
        this.toArray.length &&
        this.subject &&
        this.emailContent
      ) {
        this.loading = true;
        this.sendEmail({
          from: this.fromEmail,
          toArray: this.toArray,
          subject: this.subject,
          content: this.emailContent,
        })
          .then((message) => {
            this.loading = false;
            this.toArray = [];
            this.subject = "";
            this.emailContent = "";
            this.showSnackMessage(message);
            if (this.$route.query._id) {
              this.$router.back();
            }
          })
          .catch((error) => {
            this.loading = false;
            this.showSnackMessage(error.response.data.message);
          });
      }
    },
    imageHandler(file, Editor, cursorLocation, resetUploader) {
      var formData = new FormData();
      formData.append("file", file);
      this.uploadBlogImage(formData)
        .then((res) => {
          Editor.insertEmbed(cursorLocation, "image", res);
          resetUploader();
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data.message);
          } else {
            console.log(error);
          }
        });
    },
    onSelectAll() {
      if (!this.toArray.includes("All")) {
        this.toArray.push("All");
      }
      this.searchKey = "";
      this.$refs.toEmail.$refs.input.focus();
    },
    onSelectAllAgencies() {
      if (!this.toArray.includes("All Agencies")) {
        this.toArray.push("All Agencies");
      }
      this.searchKey = "";
      this.$refs.toEmail.$refs.input.focus();
    },
    onSelectAllVolunteers() {
      if (!this.toArray.includes("All Volunteers")) {
        this.toArray.push("All Volunteers");
      }
      this.searchKey = "";
      this.$refs.toEmail.$refs.input.focus();
    },
    showSnackMessage(message) {
      this.snackbar = true;
      this.snackMessage = message;
    },
    onCloseChip(chip) {
      this.toArray = this.toArray.filter((t) => t != chip);
    },
    onSelectFirstUser() {
      if (
        this.filteredUsers.length &&
        !this.toArray.includes(this.filteredUsers[1].email)
      ) {
        this.toArray.push(this.filteredUsers[1].email);
      }
      this.searchKey = "";
    },
    selectUser(item, toggle) {
      if (!this.toArray.includes(item.email)) {
        this.toArray.push(item.email);
      } else if (toggle) {
        this.toArray = this.toArray.filter((t) => t != item.email);
      }
      this.searchKey = "";
      this.$refs.toEmail.$refs.input.focus();
    },
    onDelete() {
      this.deleteDialog = true;
    },
    onCloseDialog() {
      this.deleteDialog = false;
    },
    onConfirmDelete() {
      this.isDeleting = true;
      this.deleteMessageLog({ _id: this.$route.query._id })
        .then(() => {
          this.$router.back();
        })
        .catch((error) => {
          this.isDeleting = false;
          console.log(error.response.data.message);
        });
    },
    isAgency(email) {
      if (this.emailTypeData[email] == undefined) {
        this.getTypeFromEmail(email)
          .then((res) => {
            this.emailTypeData[res.email] = res.type;
            this.toArray = [...this.toArray];
          })
          .catch((error) => {
            if (error.response) {
              console.log(error.response.data.message);
            } else {
              console.log(error);
            }
          });
      } else if (this.emailTypeData[email] == "Agency") return true;
      return false;
    },
    onAddFans(email) {
      if (!this.toArray.includes("Fans of " + email))
        this.toArray.push("Fans of " + email);
    },
  },
  computed: {
    ...mapGetters("auth", {
      profile: "getProfile",
    }),
  },
  watch: {
    searchKey(newValue) {
      if (newValue) {
        this.searchUsers(newValue)
          .then((res) => {
            this.filteredUsers = res;
          })
          .catch((error) => {
            if (error.response) {
              console.log(error.response.data.message);
            } else {
              console.log(error);
            }
          });
        this.toEmailPopup = true;
      }
    },
    toEmail(val, prev) {
      if (prev && val.length === prev.length) return;

      this.toEmail = val.map((v) => {
        if (typeof v === "string") {
          v = {
            text: v,
          };

          this.items.push(v);
        }

        return v;
      });
    },
  },
  mounted() {
    if (this.$route.query._id) {
      this.loading = true;
      this.getMessageLog({ _id: this.$route.query._id })
        .then((res) => {
          this.loading = false;
          this.fromEmail = res.from;
          this.toArray = res.to;
          this.subject = res.subject;
          this.emailContent = res.content;
        })
        .catch((error) => {
          console.log(error.response.data.message);
          this.loading = false;
        });
    }
  },
};
</script>
<style lang="scss">
.to-email {
  border: 1px solid rgba(0, 0, 0, 0.42);
}
.to-email:hover {
  border: 1px solid black;
}
.to-email-list-item:hover {
  background-color: #f6f6f6;
}
.new-email-input .v-input__slot {
  min-height: 25px;
}
.editor {
  height: calc(100vh - 565px);
  display: flex;
  flex-direction: column;
  color: rgba(0, 0, 0.87);
  .quillWrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .ql-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: auto;
  }
  .ql-editor {
    padding: 20px 16px 30px;
  }
  iframe {
    pointer-events: none;
  }
}
</style>
