<template>
  <keep-alive>
    <v-container fluid class="d-flex flex-column h-100 pa-0 min-height-inherit">
      <div class="d-flex flex-row h-100 pa-0 min-height-inherit">
        <side-menu class="hidden-sm-and-down" />
        <admin-message-feed-section />
      </div>
      <v-snackbar v-model="snackbar">
        {{ errorMessage }}
      </v-snackbar>
    </v-container>
  </keep-alive>
</template>
<script>
import { mapGetters } from "vuex";
import AdminMessageFeedSection from "./AdminMessageFeedSection.vue";
import SideMenu from "../home/AdminHomeSideMenu";

export default {
  components: {
    AdminMessageFeedSection,
    SideMenu,
  },
  data() {
    return {
      loading: false,
      errorMessage: null,
      snackbar: false,
      notifications: [],
      errorNotifications: [],
    };
  },
  methods: {
    showErrorMessage(message) {
      this.errorMessage = message;
      this.snackbar = true;
    },
  },
  computed: {
    ...mapGetters({
      profileType: "auth/getType",
    }),
  },
};
</script>
<style scoped></style>
